html, body, div, ol, ul, span {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}

body {
	/*line-height: 1;*/
	-webkit-text-size-adjust: 100%;
}